<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Register Farmer</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Dynamic change the address column to final level -->
                <template #cell(season)="data">
                    {{ data.item.season.startYear }}/{{ data.item.season.endYear }}
                </template>

                <template #cell(hectares)="data">
                    {{ data.item.hectares.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>

                <template #cell(volume)="data">
                    {{ (data.item.hectares * 1400).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) }}
                </template>

                <template #cell(updated_at)="data">
                    {{ data.item.updated_at | moment('DD/MM/YYYY H:m:ss') }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item :to="{ path: 'growers/' + data.item.id }">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BModal, VBModal, BButton, BTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BMedia, BAvatar, BLink,
    BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput,
    BFormCheckbox,
    BCard, BCardHeader, BCardSubTitle, BCardBody,
 } from 'bootstrap-vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import useGrowerContractsList from '@/views/cromis/grower/userGrowerContractsList'
import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'

export default {
    props: {
        growerId: {
            type: Number,
            required: true,
        },
    },

    components: {
        BCard,
        BCardHeader,
        BCardSubTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BModal,
        BButton,
        BSpinner,
        BMedia,
        BLink,
        BAvatar,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
    },
    setup (props, context) {
        const saving = ref(false)
        const serverErrors = ref(null)

        const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

        // Register module
        if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)
        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Filers
            grower_id,
        } = useGrowerContractsList()

        grower_id.value = props.growerId

        // Methods
        const invokeUpdateForm = (item) => {
            society.value = item

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            grower.value = {
                id: null,
                firstName: null,
                middleName: null,
                lastName: null,
                gender: null,
                isIF: false,
                registrationNumber: null,
                society_id: null,
                client_id: '' + client.value.type !== '0'? client.value.id : null,
                sector_id: null,
                club_id: null,
                location_id: selections.value[selections.value.length - 1].model !== null? selections.value[selections.value.length - 1].model.id : null,
                contract: {
                    hectares: 0,
                    labors: 1,
                    type: 1,
                    motherBeds: 0,
                    resetBeds: 0,
                    barns: [
                        {
                            length: 0,
                            width: 0,
                            tiers: 0,
                            topVent: false,
                            bottomVent: false,
                            slot: false,
                            isComplete: false
                        }
                    ]
                }
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false 
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (grower.value.id === null || grower.value.id === 0)
                handleCreate()
            else
                handleUpdate(grower.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-grower/create', grower.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Grower ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            console.log('updating...')
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-grower/update', item.id, item)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to grower ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                        showConfirmButton: false,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-grower/remove', id)
                        .then(response => {
                            refetch()
                        })
                        .catch(error => {
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: false,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        })
        }

        return {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Validations
            required,
            integer,

            // Methods and Form refs
            saving,
            serverErrors,
            invokeCreateForm,
            invokeUpdateForm,
            isFormValid,
            submit,
            handleCreate,
            handleUpdate,
            remove,
        }
    }
}
</script>
    
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
