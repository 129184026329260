<template>
    <div>
        <!-- Table Container Card -->
        <!-- <b-card no-body class="mb-0"> -->
            <table striped hover small class="table">
                <thead>
                <tr>
                    <th>SN</th>
                    <th>FULL NAME</th>
                    <th>RELATIONSHIP TYPE</th>
                    <th>PHONE NUMBER</th>
                </tr>
                </thead>
               <!-- <tbody>
                 <tr v-for="dependant in grower.dependants" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>{{ dependant.full_name }}</td>
                    <td>{{ dependant.relation }}</td>
                    <td>{{ dependant.phone_number }}</td> -->
                    <!-- <td>
                        <div class="btn-group" role="group">
                            <router-link :to="{name: 'edit', params: { id: dependant.id }}" class="btn btn-success">Edit</router-link>
                            <button class="btn btn-danger" @click="deletedependant(dependant.id)">Delete</button>
                        </div>
                    </td> -->
                <!-- </tr>
                </tbody> -->
            </table> 
        <!-- </b-card> -->
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
export default {
    props: [
        'grower'
    ],

    components: {
    },
    setup (props, context) {
        const saving = ref(false)
        const grower = ref(null)
        const serverErrors = ref(null)

        return {
            grower,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
