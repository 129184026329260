<style>
.green-strip {
    border-left: 4px solid #28c76f;
    height: 10px !important;
    margin-bottom: 5px;
    padding: 4px;
    /* margin-left: -27px; */
}

.full-width-table {
    width: 100%;
}
</style>

<template>
    <div>

        <!-- Form Modal -->
        <b-modal
            id="modal-loan-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc size="lg"
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="loan.id === null">Add Loan</h5>
                <h5 v-if="loan.id !== null">Edit Loan</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="sm" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <!-- <b-row>
                    <b-col cols="12">
                        <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert>

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>
                    </b-col>
                </b-row> -->

                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="5">
                                <b-form-group labe-for="package_id" label="Loan Type">
                                    <validation-provider #default="{ errors }" name="package_id" rules="">
                                        <b-form-select
                                            size="sm"
                                            v-model="loan.package_id"
                                        >
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option
                                                v-for="packageL in packages"
                                                :key="packageL.id"
                                                :value="packageL.id"
                                            >
                                                {{ packageL.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.package_id" class="text-danger">{{serverErrors.package_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="loan.package_id != null && packages.filter((e) => e.id == loan.package_id)[0].isCash">
                            <b-col cols="5">
                                <b-form-group labe-for="paymentMethod" label="Payment Method">
                                    <validation-provider #default="{ errors }" name="paymentMethod" rules="">
                                        <b-form-select
                                            id="paymentMethod"
                                            name="paymentMethod"
                                            size="sm"
                                            v-model="loan.paymentMethod"
                                        >
                                            <b-form-select-option
                                                v-for="method in paymentMethods"
                                                :key="method.id"
                                                :value="method.id"
                                            >
                                                {{ method.label }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.paymentMethod" class="text-danger">{{serverErrors.paymentMethod[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="7" v-if="loan.paymentMethod != null && paymentMethods.filter((e) => e.id == loan.paymentMethod)[0].triggerInto">
                                <b-form-group labe-for="paid_id" :label="paymentMethods.filter((e) => e.id == loan.paymentMethod)[0].label">
                                    <validation-provider #default="{ errors }" name="paid_id" rules="">
                                        <b-form-select
                                            id="paid_id"
                                            name="paid_id"
                                            size="sm"
                                            v-model="loan.paid_id"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.paid_id" class="text-danger">{{serverErrors.paid_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="loan.package_id != null && packages.filter((e) => e.id == loan.package_id)[0].isCash">
                            <b-col cols="12" v-if="loan.paymentMethod != null && paymentMethods.filter((e) => e.id == loan.paymentMethod)[0].triggerFrom">
                                <b-form-group labe-for="from_id" label="From Account">
                                    <validation-provider #default="{ errors }" name="from_id" rules="">
                                        <b-form-select
                                            id="from_id"
                                            name="from_id"
                                            size="sm"
                                            v-model="loan.from_id"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.from_id" class="text-danger">{{serverErrors.from_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="loan.package_id != null && packages.filter((e) => e.id == loan.package_id)[0].isCash">
                            <b-col cols="5">
                                <b-form-group labe-for="dateTaken" label="Disbursed Date">
                                    <validation-provider #default="{ errors }" name="dateTaken" rules="">
                                        <b-form-datepicker
                                            id="dateTaken"
                                            name="dateTaken"
                                            v-model="loan.dateTaken"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.dateTaken" class="text-danger">{{serverErrors.dateTaken[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="amount" label="Disbursed Amount">
                                    <validation-provider #default="{ errors }" name="amount" rules="">
                                        <b-form-input
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            v-model="loan.amount"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.amount" class="text-danger">{{serverErrors.amount[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Inputs loans starts here -->
                        <b-row v-if="loan.package_id != null && !packages.filter((e) => e.id == loan.package_id)[0].isCash">
                            <b-col cols="12">
                                <b-button
                                    size="sm"
                                    class="add-owner mb-1 pull-right"
                                    variant="outline-success"
                                    @click="addElement()"
                                >
                                    Add Input
                                </b-button>
                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Input</th>
                                        <th width="13%">Quantity</th>
                                        <th>Is Topup?</th>
                                        <th>Date Taken</th>
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="element, index in  loan.inputs" :key="index">
                                        <td scope="row">
                                            <div>
                                                <validation-provider #default="{ errors }" name="id" rules="">
                                                    <b-form-select
                                                        size="sm"
                                                        v-model="element.id"
                                                    >
                                                        <b-form-select-option :value="null">Select...</b-form-select-option>
                                                        <b-form-select-option v-for="input in inputs" :key="input.id" :value="input.id">
                                                            {{ input.name.toUpperCase() }}
                                                        </b-form-select-option>
                                                    </b-form-select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small v-if="serverErrors && serverErrors.inputs.id" class="text-danger">{{ serverErrors.inputs.id[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </td>

                                        <td scope="row">
                                            <validation-provider #default="{ errors }" name="quantity" rules="">
                                                <b-form-input type="number" id="quantity" name="quantity" v-model="element.quantity" size="sm" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small v-if="serverErrors && serverErrors.inputs.quantity" class="text-danger">{{ serverErrors.inputs.quantity[0] }}</small>
                                            </validation-provider>
                                        </td>
                                        <td scope="row">
                                            <div>
                                                <b-form-checkbox :checked="element.topup" class="custom-control-success" name="check-button" switch v-model="element.topup">
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckCircleIcon" />
                                                    </span>
                                                </b-form-checkbox>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <validation-provider #default="{ errors }" name="dateTaken" rules="">
                                                    <b-form-datepicker
                                                        id="dateTaken"
                                                        name="dateTaken"
                                                        v-model="element.dateTaken"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="sm"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        locale="en"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small class="text-danger" v-if="serverErrors && serverErrors.dateTaken">{{ serverErrors.dateTaken[0] }}</small>
                                                </validation-provider>
                                                <!-- <b-form-input v-model="element.dateTaken" type="date" size="sm"/> -->
                                            </div>
                                        </td>
                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon
                                                    icon="TrashIcon"
                                                    size="8"
                                                    class="text-danger stroke-current"
                                                />
                                            </b-button>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </b-row>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Loan</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive
                :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(season)="data">
                    <span class="green-strip"></span>
                    <span>{{ data.item.season.startYear }} / {{ data.item.season.endYear }} </span>
                </template>

                <template #cell(inputs)="data">

                    <table class="full-width-table">
                        <thead>
                            <tr class="text-center">
                                <th>Input</th>
                                <th width="13%">Quantity</th>
                                <th>Applied</th>
                                <th>Amount</th>
                                <th>Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="element, index in  data.item.inputs" :key="index" class="text-center">
                                <td scope="row" style="text-align:left;">
                                    <div>
                                        {{ element.name }}
                                    </div>
                                </td>
                                <td scope="row">
                                    <div>
                                        {{ element.quantity }}
                                    </div>
                                </td>
                                <td scope="row">
                                    <div>
                                        {{ element.applied }}
                                    </div>
                                </td>
                                <td scope="row">
                                    <div>
                                        {{ element.amount }}
                                    </div>
                                </td>

                                <td>
                                    <div>
                                        {{ element.unit.name }}<small> ({{ element.unit.abbreviation }})</small>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BModal, VBModal, BButton, BTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BMedia, BAvatar, BLink,
    BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput,
    BFormCheckbox,
    BCard, BCardHeader, BCardSubTitle, BCardBody, BFormDatepicker,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import useGrowerLoanList from '@/views/cromis/grower/useGrowerLoanList'
import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'


export default {
    props: {
        growerId: {
            type: Number,
            required: true,
        },
    },

    components: {
        BCard,
        BCardHeader,
        BCardSubTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BFormDatepicker,
        BModal,
        BButton,
        BSpinner,
        BMedia,
        BLink,
        BAvatar,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
    },

    setup(props, context) {
        const saving = ref(false)
        const serverErrors = ref(null)
        const myModal = ref(null)
        const packages = ref([])
        const inputs = ref([])
        const loans = ref([])
        const season_id = ref(0)
        const paymentMethods = ref([
            {id: null, label: 'Salect...', triggerInto: false, triggerFrom: false},
            {id: 1, label: 'Cash', triggerInto: false, triggerFrom: true},
            {id: 2, label: 'Cheque', triggerInto: false, triggerFrom: true},
            {id: 3, label: 'Bank Account', triggerInto: true, triggerFrom: true},
            {id: 4, label: 'Mobile Number', triggerInto: true, triggerFrom: true},
        ])

        const errosOcurs = ref(null)
        const loan = ref({
            id: null,
            package_id: null,
            season_id: null,
            debtor_type: 1,
            debtor_id: null,
            paymentMethod: null,
            paid_id: null,
            from_id: null,
            inputs: [
                {
                    id: null,
                    topup: false,
                    quantity: null,
                    dateTaken: null,
                }
            ]
        })

        const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

        // Register module
        if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-grower/packages')
                .then(response => {
                    packages.value = response.data.packages
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-grower/inputs')
                .then(response => {
                    inputs.value = response.data.inputs
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-grower/seasons')
                .then(response => {
                    // console.log(response)
                    response.data.seasons.forEach(el => {
                        if (el.isRunning == true) {
                            loan.value.season_id = el.id
                            season_id.value = el.id
                        }
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const {
            fetch,
            columns,
            perPage,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            currentPage,
            from,
            to,
            totalRecords,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Filers
            grower_id,
        } = useGrowerLoanList()

        grower_id.value = props.growerId

        // Methods
        const invokeUpdateForm = (item) => {
            // society.value = item

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            loan.value = {
                id: null,
                package_id: null,
                season_id: null,
                debtor_type: 1,
                debtor_id: grower_id.value,
                paymentMethod: null,
                paid_id: null,
                from_id: null,
                inputs: [
                    {
                        id: null,
                        topup: false,
                        quantity: null,
                        dateTaken: null,
                    }
                ]
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (loan.value.id === null || loan.value.id === 0)
                handleCreate()
            else
                handleUpdate(loan.value)
        }

        const handleCreate = async () => {
            console.log(loan.value)
            saving.value = true

            loan.value.season_id = season_id.value

            await store.dispatch('cromis-grower/loanSave', loan.value).then(response => {
                refetch()
                saving.value = false

                myModal.value.hide()

                context.root.$swal({
                    icon: 'success',
                    text: `Farmer ${response.data.person.firstName} ${response.data.person.lastName} has been Associated with Loan successfully!`,
                    showConfirmButton: true,
                    timer: 5000,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            })
                .catch(error => {
                    saving.value = false
                    console.log(error.responce)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const getPackage = async () => {

        }

        const handleUpdate = async (item) => {
            saving.value = true
            console.log('updating...')
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-grower/update', item.id, item).then(response => {
                refetch()
                saving.value = false

                myModal.value.hide()

                context.root.$swal({
                    icon: 'success',
                    text: `Changes to farmer ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                    showConfirmButton: false,
                    timer: 5000,
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                    buttonsStyling: false,
                })
            })
                .catch(error => {
                    saving.value = false
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })
        }

        const addElement = () => {
            loan.value.inputs.push({ id: '', end: '', quantity: '', dateTaken: '' })

            if (loan.value.inputs.length > 0) {


                console.log(loan.value.inputs)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (loan.value.inputs.length > 1) {
                loan.value.inputs.splice(index, 1)
                loan.value.inputs = loan.value.inputs
            }
        }

        const remove = async (id) => {
            await store.dispatch('cromis-grower/remove', id).then(response => {
                refetch()
            }).catch(error => {
                context.root.$swal({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Something went wrong. See tech support',
                    showConfirmButton: false,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    buttonsStyling: false,
                })
            })
        }

        return {
            fetch,
            columns,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            perPage,
            currentPage,
            from,
            to,
            totalRecords,
            myModal,
            addElement,
            removeRow,
            errosOcurs,
            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Validations
            required,
            integer,

            // Methods and Form refs
            saving,
            serverErrors,
            invokeCreateForm,
            invokeUpdateForm,
            isFormValid,
            submit,
            handleCreate,
            handleUpdate,
            remove,
            packages,
            loan,
            getPackage,
            inputs,
            loans,
            paymentMethods,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
