<template>
    <div>
        <!-- Table Container Card -->
        <!-- <b-card no-body class="mb-0"> -->
            <table striped hover small class="table">
                <thead>
                <tr>
                    <th>SN</th>
                    <th>BANK NAME</th>
                    <th>ACCOUNT NAME</th>
                    <th>ACCOUNT NUMBER</th>
                </tr>
                </thead>
                <tbody>
                 <!-- <tr v-for="bank_account in grower.bank_accounts" :key="bank_account.id"> -->
                    <!-- <tr v-if="(bank_accounts, index)  in grower.bank_accounts"> -->
                        <!-- <tr v-for="(account, index) in bankAccounts" :key="index"></tr> -->
                    <tr v-for="(bank_account, index)  in bank_accounts" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>{{ bank_account.bank_name }}</td>
                    <td>{{ bank_account.account_name }}</td>
                    <td>{{ bank_account.account_no }}</td> 
                    <td>
                        <div class="btn-group" role="group">
                            <router-link :to="{name: 'edit', params: { id: bank_account.id }}" class="btn btn-success">Edit</router-link>
                            <button class="btn btn-danger" @click="deletebank_account(bank_account.id)">Delete</button>
                        </div>
                    </td>
                 </tr> 
                </tbody> 
            </table> 
        <!-- </b-card> -->
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
export default {
    props: [
        'grower'
    ],

    components: {
    },
    setup (props, context) {
        const saving = ref(false)
        const grower = ref(null)
        const serverErrors = ref(null)

        return {
            grower,
        }
    }
}
 </script> 

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>




